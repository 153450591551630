import { NavLink, Link, Routes, Route } from "react-router-dom";

import '../css/Splash.css';

const Splash = (props) => {
    var {original, remix} = props
    original = original.data
    remix = remix.data
    // var cards = [... original, ...remix]
    // Seattle NFT museum feedback remove original cards from splash page
    var cards = [...remix]
    var gallery = cards.filter(obj => { return obj.fileType === ".jpeg" || obj.fileType === ".jpg"})
    gallery = gallery.sort(() => Math.random() - 0.5)


    return (
        <div className="spash-page">
            <section className="splash-card-vertical-gallery">
            {gallery.map((card, index) => {
                let directory
                if(card.collection_listing !== "https://opensea.io/collection/curiocardswrapper"){
                    directory = 'remix'
                }else{
                    directory = 'original'
                }
                return <img className="card-viewer-image" src={`../images/${directory}/poster_${card.hash}${card.fileType}`} loading="lazy" alt={`${card.name}`} key={card.hash} />
            })}
            </section>
            <section className="spash-text-container">
                <h1 className="spash-heading">Fan-made remixes of the first ethereum art show</h1>
                <Link className="spash-start-button" to="/gallery">Start</Link>
            </section>
        </div>
    )
}
export default Splash