const SearchBar = ({ posts, setSearchResults }) => {
    console.log(posts)
    const handleSubmit = (e) => e.preventDefault()

    const handleSearchChange = (e) => {
        if (!e.target.value) return setSearchResults(posts)

        const resultsArray = posts.filter(
                                post => 
                                    post.artist.toLowerCase().includes(e.target.value.toLowerCase()) 
                                    || post.name.toLowerCase().includes(e.target.value)
                                    || post.description.toLowerCase().includes(e.target.value.toLowerCase()) 
                                    || post.year === Number(e.target.value.toLowerCase())
                                    || post.type.toLowerCase().includes(e.target.value.toLowerCase())
                                ) 

        setSearchResults(resultsArray)
    }

    return (
            <form onSubmit={handleSubmit} className="search-bar">
                <input
                    className="search__input"
                    type="text"
                    id="search"
                    onChange={handleSearchChange}
                    placeholder="Enter your search terms here"
                />
                {/* <button className="search__button">
                    Search
                </button> */}
            </form>
    )
}
export default SearchBar