import CardDetails from "../components/CardDetails";
import {TfiAngleDoubleDown} from "react-icons/tfi" 
import '../css/ListResults.css';


const SearchResult = (props) => {
    console.log(props)
    let card = props.post
    let getMedia = (card) => {
        //switch on card number
        let directory
        if(card.collection_listing !== "https://opensea.io/collection/curiocardswrapper"){
            directory = 'remix'
        }else{
            directory = 'original'
        }
        if(card.hash === "curio-cards-christies-auction-2021"){
          directory = 'original'
        }
        switch (card.fileType) {
          case '.png':
            return <img className="list-results-card-media" src={`../images/${directory}/poster_${card.hash}${card.fileType}`} loading="lazy" alt={`${card.name}`} key={card.hash} />
          case '.jpeg':
            return <img className="list-results-card-media" src={`../images/${directory}/poster_${card.hash}${card.fileType}`} loading="lazy" alt={`${card.name}`} key={card.hash} />
          case '.jpg':
            return <img className="list-results-card-media" src={`../images/${directory}/poster_${card.hash}${card.fileType}`} loading="lazy" alt={`${card.name}`} key={card.hash} />
            case '.gif':
              return <img className="list-results-card-media" src={`../images/${directory}/${card.hash}${card.fileType}`} loading="lazy" alt={`${card.name}`} key={card.hash} />                
          case '.mp4':
            // in production set to preload to metadata and have poster files 
            return (<video className="list-results-card-media" controls playsInline loop preload="metadata" poster={`../images/${directory}/${card.poster}${card.posterFileType}`} alt={`${card.name}`} key={card.hash}>
              <source src={`https://curiocardsremixdirectory.s3.amazonaws.com/images/remix/${card.hash}${card.fileType}`} type="video/mp4" />
            </video>)
          case '.glb':
            return (
            <div className="list-results-card-model-view-css-fix" key={card.hash}>
              <model-viewer
                alt="3d model"
                src={`https://curiocardsremixdirectory.s3.amazonaws.com/images/remix/${card.hash}${card.fileType}`}
                poster={`../images/${directory}/${card.poster}${card.posterFileType}`}
                shadow-intensity="1"
                camera-controls touch-action="pan-y">
              </model-viewer>
            </div>)
          default:
            return null
        }
      }
      let cardMedia = getMedia(card) 
    return (
        <article className="list-results-card">
            {/* <h2>{card.artist}</h2>
            <p>{card.title}</p>
            <p>{card.email}</p>
            <p>{card.twitter}</p>
            <p>{card.instagram}</p>
            <p>{card.collection}</p>
            <p>{card.collection_listing}</p> */}
               {cardMedia}
            <p className="list-results-card-description">
              {card.description}
            </p>
            {card.description.length > 125 ? <TfiAngleDoubleDown className="list-results-card-scroll-arrow"/> : ""}
            <p className="list-results-card-type">{card.type} Year: {card.year}</p>
            <CardDetails card={card} />
        </article>
    )
}
export default SearchResult