
import axios from "axios"

const cardsApi = axios.create({
    baseURL: "https://curiocardsremixdirectory.s3.amazonaws.com/data/"
})

export const getOriginal = async () => {
    const response = await cardsApi.get("original.json")
    return response.data
}

export const getRemix = async () => {
    const response = await cardsApi.get("remix.json")
    return response.data
}