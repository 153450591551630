import { TfiNewWindow, TfiUser, TfiGallery, TfiInfoAlt } from "react-icons/tfi"
import { NavLink, Link, Routes, Route } from "react-router-dom";
import '../css/Details.css';

const Details = (props) => {
    let card = props.card
    return (
        <span className="card-viewer-info">
        <Link to={`/card/${card.hash}`} rel="noreferrer">
          {`${card.name} info`    }
            <TfiInfoAlt />
        </Link>
        <Link to={`/artist/${card.artist}`} rel="noreferrer">
          {`@${card.artist}`  }
            <TfiUser />
        </Link>
        {/* <a href={`/collection/${card.collection}`} target="_blank" rel="noreferrer">
          {card.collection  }
            <TfiGallery />
        </a> */}
      </span>
    )
}

export default Details