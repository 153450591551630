import { NavLink, useParams, useResolvedPath } from "react-router-dom";
import {TfiTwitterAlt, TfiInstagram, TfiEmail} from "react-icons/tfi" 
import '../css/Artist.css';
import '../css/CardViewer.css';
import { ThemeContext, themes } from '../components/ThemeContext';
import { SiOpensea } from "react-icons/si"
//TfiTwitterAlt

const Artist = (props) => {
    var {original, remix} = props
    original = original.data
    remix = remix.data

    // combine both orginal and remix card arrays
    var cards = [... original, ...remix]
    // create list of unique artists
    var artists = [... new Set(cards.map(obj => obj.artist))] 

    // console.log(cards)
    // get url path to filter with
    const { pathname } = useResolvedPath();
    // console.log(pathname)
    // destructure using / to get value in between
    var [blank, type, term] = pathname.split('/')
    console.log(term)
    if(term === ""){
      term = "phneep"
    }
    const standardizedTerm = unescape(term)
    // console.log(type)
    // console.log(term)
    // 
    // const { collection, artist, title, terms } = useParams()
    // console.log(terms, collection)

    let getMedia = (card) => {
        //switch on card number
        // fix to swith on origin, original || remix
        let directory
        if(card.collection_listing !== "https://opensea.io/collection/curiocardswrapper"){
            directory = 'remix'
        }else{
            directory = 'original'
        }
        switch (card.fileType) {
          case '.png':
            return <img className="card-viewer-image" src={`../images/${directory}/poster_${card.hash}${card.fileType}`} loading="lazy" alt={`${card.name}`} key={card.hash} />
          case '.jpeg':
            return <img className="card-viewer-image" src={`../images/${directory}/poster_${card.hash}${card.fileType}`} loading="lazy" alt={`${card.name}`} key={card.hash} />
          case '.jpg':
            return <img className="card-viewer-image" src={`../images/${directory}/poster_${card.hash}${card.fileType}`} loading="lazy" alt={`${card.name}`} key={card.hash} />
            case '.gif':
              return <img className="card-viewer-image" src={`../images/${directory}/${card.hash}${card.fileType}`} loading="lazy" alt={`${card.name}`} key={card.hash} />                
          case '.mp4':
            // in production set to preload to metadata and have poster files 
            return (<video className="card-viewer-video" controls playsInline loop preload="metadata" poster={`../images/${directory}/${card.poster}${card.posterFileType}`} alt={`${card.name}`} key={card.hash}>
              <source src={`https://curiocardsremixdirectory.s3.amazonaws.com/images/remix/${card.hash}${card.fileType}`} type="video/mp4" />
            </video>)
          case '.glb':
            return (
            <div className="model-view-css-fix" key={card.hash}>
              <model-viewer
                alt="3d model"
                src={`https://curiocardsremixdirectory.s3.amazonaws.com/images/remix/${card.hash}${card.fileType}`}
                poster={`../images/${directory}/${card.poster}${card.posterFileType}`}
                shadow-intensity="1"
                camera-controls touch-action="pan-y">
              </model-viewer>
            </div>)
          default:
            return null
        }
      }


    let card = []
    switch (type) {
        case 'artist':
            card = [...cards.filter(obj => obj.artist === standardizedTerm)]
            break;
        case 'collection':
            // code block
            card = [...cards.filter(obj => obj.collection === standardizedTerm)]
            break;
        case 'card':
          // code block
          card = [...cards.filter(obj => obj.hash === standardizedTerm)]
          break;            
        default:
        // code block
    }
    // add media links for artists and not the collection
    // may change in the future
    let mediaLinks = 
      type === 'artist' || type === 'card' ?
        <span className="artist-links">
                  {/* {card[0].email.length > 0 ?                         
                  <a href={`${card.email}`} target="_blank" rel="noreferrer">
                      <TfiEmail />
                  </a> : ""}                            */}
                  {card[0].twitter.length > 0 ?                         
                  <a href={`${card.twitter}`} target="_blank" rel="noreferrer">
                      <TfiTwitterAlt />
                  </a> : ""}                        
                  {/* {card[0].instagram.length > 0 ?                         
                  <a href={`${card.instagram}`} target="_blank" rel="noreferrer">
                      <TfiInstagram />
                  </a> : ""}                       */}
        </span> : ""

    console.log(card)
    let [thisCard] = card
    return (
      <div className="artist-app">
        <aside className="artist-list">
          {artists.map((artist) => (
            // use the url to focus the correct card based on the number in the url
            <NavLink className="artist-list-button" to={`/artist/${artist}`}  key={artist} rel="noreferrer">
              {artist}
            </NavLink>
          ))}
        </aside>
        <div className="artist-details-and-results">
          <div className="artist-details"> 
            <h1 className="artist-name">{thisCard.artist}</h1>
            <div className="artist-socials">

              <a className="artist-twitter" href={thisCard.twitter} target="_blank" rel="noreferrer">
              
                <TfiTwitterAlt
                  onMouseOver={({target})=>target.style.color="var(--highlight)"}
                  onMouseOut={({target})=>target.style.color="var(--main-text)"}
                />
              </a>
              <a className="artist-os" href={thisCard.marketplace} target="_blank" rel="noreferrer">
              
                <SiOpensea
                    onMouseOver={({target})=>target.style.color="#F26241"}
                    onMouseOut={({target})=>target.style.color="var(--main-text)"}
                />
              </a>
            </div>
          </div> 
          <article className="artist-results" >
          
            {card.map((card, index) => {
                // console.log(card)
                let cardMedia = getMedia(card)  
                console.log(cardMedia)  
                return (
                    <section className="artist-card" key={index}>
                        {cardMedia}
                    </section>
                )
            })}
          </article>
        </div>
      </div>

    )
}
export default Artist