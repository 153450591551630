import { useState, useEffect } from 'react'
import { getPosts } from '../pages/axios'
import SearchBar from './SearchBar'
import SearchResultList from './SearchResultList'
import '../css/Search.css';
import '../css/CardViewer.css';

const Search = (props) => {
  const [posts, setPosts] = useState([])
  const [searchResults, setSearchResults] = useState([])

  var {original} = props
  original = original.data
  var {remix} = props
  remix = remix.data

  var cards = [... original, ...remix]
  useEffect(() => {
      setPosts(cards)
      setSearchResults(cards)   
  }, [])

  return (
    <div className="search-app">
      <SearchBar posts={posts} setSearchResults={setSearchResults}/>
      <SearchResultList searchResults={searchResults}/>
    </div>
  )
}

export default Search