import SearchResult from "./SearchResult"
import '../css/ListResults.css';

const SearchResultList = ({ searchResults }) => {

    const results = searchResults.map(post => <SearchResult key={post.ipfs_hash || post.marketplace} post={post} />)

    const content = results?.length ? results : <article><p>No Matching SearchResults</p></article>

    return (
        <main className="list-results">
            {content}
        </main>
    )
}
export default SearchResultList