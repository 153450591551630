import '../css/About.css';

const About = () => {
    return (
        <div className="about-app">
            <h1 className="about-title">About the remixes</h1>
            <article className="about-article" >
                <h2>These remixes are created by the Curio Cards community which means anyone can apply to have their art added to the directory</h2>
                <a href="https://curio.cards/"  target="_blank">Curio Cards</a>
            </article>
            <img className="about-image" src="./images/original/poster_QmZy6RuSuADSErzpWAoMkbBNanrvPLmAz4pwNxu9RKmnAC.gif"
                alt="Curio Card #1 Apple"></img>
        </div>
    )
}

export default About