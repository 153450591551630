import { createContext } from "react";

export const themes = {
  original: "original",
  light: "light",
  dark: "dark"
};

export const ThemeContext = createContext({
  theme: themes.original,
  changeTheme: () => { },
});