import { Link, useParams, useResolvedPath } from "react-router-dom";
import { TfiInfoAlt } from "react-icons/tfi"
import '../css/CardDetails.css';
import '../css/ListResults.css';

const CardDetails = (props) => {
    let card = props.card
    return (
        <span className="list-results-card-link">
        <Link to={`/card/${card.hash}`} rel="noreferrer">
            <TfiInfoAlt />
        </Link>
      </span>
    )
}

export default CardDetails