import { useState, useEffect } from 'react'
import { NavLink, Link, Routes, Route } from "react-router-dom";
import Gallery from "./pages/Gallery";
import Submit from "./pages/Submit";
import About from "./pages/About";
import Search from "./pages/Search";
import Card from "./pages/Card";
import Splash from "./pages/Splash";
import Artist from './pages/Artist';
import 'animate.css';
import './App.css';
import { TfiHome, TfiBook, TfiSearch, TfiWrite, TfiGallery, TfiUser, TfiBrushAlt, TfiShoppingCart } from "react-icons/tfi"
import { ThemeContext, themes } from './components/ThemeContext';


// import {original, remix} from './Data.js';
import axios from "axios"
import { useQueries, useQuery, useMutation, useQueryClient } from "react-query"
import { getRemix, getOriginal } from './api/api';

function App() {
  const [cards, setCards] = useState([])
  const [searchResults, setSearchResults] = useState([])

  const [originalQuery, remixQuery] = useQueries([
    {
      queryKey: ['original'],
      queryFn: getOriginal,
    },
    {
      queryKey: ['remix'],
      queryFn: getRemix,
    },
  ]);

  let activeStyle = {
    textDecoration: "underline",
    // needs context to not apply if original and to apply to svg emoji
    color: "var(--highlight)"
  };

  let unactiveStyle = {
    textDecoration: "none",
  };

  let artistUnactiveStyle = {

  };


  // let activeClassName = "underline";

  if (originalQuery.isLoading)
    return (<p>Loading original card data...</p>);
  if (remixQuery.isLoading)
    return (<p>Loading remix card data...</p>);
  if (originalQuery.error)
    return (<p>An error has occurred with the original data request: ' + originalQuery.error.message</p>);
  if (remixQuery.error)
    return (<p>'An error has occurred with the remix data request: ' + remixQuery.error.message</p>);
  return (


    <ThemeContext.Consumer>
      {({ theme, changeTheme }) => {
        return (
          <>
            <div className="app-header-logo-container" >
              <NavLink to="/gallery">
                {() => {
                  switch (theme) {
                    case themes.light:
                      return <img className="app-logo" src='../CC_Remix_Black.png'></img>
                    case themes.dark:
                      return <img className="app-logo" src='../CC_Remix_White.png'></img>
                    case themes.original:
                      return <img className="app-logo" src='../CC_Remix_White.png'></img>
                    default:
                      return <img className="app-logo" src='../CC_Remix_White.png'></img>
                  }
                }
                }

              </NavLink>
              <header className="app-header">

                <nav className="app-nav">
                  <NavLink to="/"
                    style={({ isActive }) =>
                      isActive ? activeStyle : unactiveStyle
                    }
                  >
                    {({ isActive }) =>
                      isActive ? <><TfiHome style={activeStyle} /> Home</> : <><TfiHome /> Home</>
                    }
                  </NavLink>
                  <NavLink to="/gallery"
                    style={({ isActive }) =>
                      isActive ? activeStyle : unactiveStyle
                    }
                  >
                    {({ isActive }) =>
                      isActive ? <><TfiGallery style={activeStyle} />  Gallery</> : <><TfiGallery />  Gallery</>
                    }
                  </NavLink>
                  <NavLink end to="/artist/phneep"
                    style={({ isActive }) =>
                      isActive ? activeStyle : unactiveStyle
                    }
                  >
                    {({ isActive }) =>
                      isActive ? <><TfiUser style={activeStyle} />  Artist</> : <><TfiUser />  Artist</>
                    }

                  </NavLink>
                  <NavLink to="/about"
                    style={({ isActive }) =>
                      isActive ? activeStyle : unactiveStyle
                    }
                  >
                    {({ isActive }) =>
                      isActive ? <><TfiBook style={activeStyle} />  About</> : <><TfiBook />  About</>
                    }
                  </NavLink>
                  <NavLink to="/submit"
                    style={({ isActive }) =>
                      isActive ? activeStyle : unactiveStyle
                    }
                  >
                    {({ isActive }) =>
                      isActive ? <><TfiWrite style={activeStyle} />  Submit</> : <><TfiWrite />  Submit</>
                    }
                  </NavLink>
                  <NavLink to="/search"
                    style={({ isActive }) =>
                      isActive ? activeStyle : unactiveStyle
                    }
                  >
                    {({ isActive }) =>
                      isActive ? <><TfiSearch style={activeStyle} />  Search</> : <><TfiSearch />  Search</>
                    }
                  </NavLink>
                  <a target="_blank" href="https://merch.curio.cards/" className='merch-link'>
                    <TfiShoppingCart /> Merch
                  </a>
                  <span>
                    <TfiBrushAlt style={{ color: "var(--main-text)" }} />
                    {'\u00A0 \u00A0'}
                    <select name="Themes" id="theme-select"
                      defaultValue={localStorage.getItem("theme") ?? "dark"}
                      onChange={e => changeTheme(e.target.value)}
                    >
                      <option value="original">Original</option>
                      <option value="dark">Dark</option>
                      <option value="light">Light</option>
                    </select>
                  </span>
                </nav>
              </header>
            </div>

            <Routes>
              <Route path="/" element={<Splash original={originalQuery} remix={remixQuery} />} />
              <Route exact path="/gallery" element={<Gallery original={originalQuery} remix={remixQuery} />} />
              <Route path="/submit" element={<Submit />} />
              <Route path="/about" element={<About />} />
              <Route path="/search" element={<Search original={originalQuery} remix={remixQuery} />} />
              <Route path='card/:term' element={<Card original={originalQuery} remix={remixQuery} />} />
              <Route path='artist/:term' element={<Artist original={originalQuery} remix={remixQuery} />} />
              <Route path='artist/' element={<Artist original={originalQuery} remix={remixQuery} />} />
              <Route path='collection/:term' element={<Card original={originalQuery} remix={remixQuery} />} />
            </Routes>
          </>
        )
      }}
    </ThemeContext.Consumer>

  );
}
export default App