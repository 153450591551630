import React, { useState, useEffect } from 'react';
import { ThemeContext, themes } from '../components/ThemeContext';

export default function ThemeContextWrapper(props) {
  const [theme, setTheme] = useState(localStorage.getItem("theme") || []);

  function changeTheme(theme) {
    localStorage.setItem("theme", theme);
    setTheme(theme);
  }
  function getTheme() {
    return theme
  }

  // reference root CSS in order to change styles applied
  var r = document.querySelector(':root');

  useEffect(() => {
    // pass a string of light, dark or original
    switch (theme) {
      // pattern match on string of light, dark or original via the themes object properties values
      case themes.light:
        console.log("???")
        r.style.setProperty('--main', 'white');
        r.style.setProperty('--main-text', 'black');
        break;
      case themes.original:
        r.style.setProperty('--main', '#F9D6CA');
        r.style.setProperty('--main-text', 'white');
        break;
      case themes.dark:
      default: // dark mode by default
        r.style.setProperty('--main', 'black');
        r.style.setProperty('--main-text', 'white');
        break;
    }
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme: theme, changeTheme: changeTheme, getTheme: getTheme }}>
      {props.children}
    </ThemeContext.Provider>
  );
}