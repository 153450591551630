import '../css/Submit.css';

const Submit = () => {
    return (
        <div className="submit-app">
        <h2 className="submit-title">Submit your artwork here</h2>
        <iframe className="submit-iframe" src="https://airtable.com/embed/shroRMohHOoB9M0iW"></iframe>
        <img className="submit-image" src="./images/original/poster_QmZGrL7HrUmkLuUxp7NV4n1H8tHkurmBAdsx8jzJp4v1tn.jpeg" 
                alt="Curio Card #1 Apple"></img>
        </div>
    )
  }
  
  export default Submit