import axios from "axios"

export const api = axios.create({
    baseURL: 'https://curiocardsremixdirectory.s3.amazonaws.com/data/'
})

export const getPosts = async () => {
    const response = await api.get('cardlist.json')
    return response.data
}
//'data.json'