import { useEffect, useState } from "react";
import Details from "../components/Details";
import CardHolder from "../components/CardHolder";
import '../css/Gallery.css';
import '../css/CardViewer.css';

function Gallery(props) {



  // destructure the orginal and remix card data
  var { original, remix } = props
  original = original.data
  remix = remix.data

  // create React State to display cards
  const [displayedRemixes, setDisplayedRemixes] = useState([]);
  const [currentCard, setCurrentCard] = useState([]);


  // This function takes the obj.card value and uses that
  // to display the original card and filter the remix array
  // by obj.card = pass value
  const updateRemix = (originalCardNumber) => {
    // console.log(remix)
    let newCards = remix.filter((card) => {
      // console.log(card.original)
      return card.card === originalCardNumber;
    })
    // console.log(newCards)
    setDisplayedRemixes(newCards)
    setCurrentCard(original[originalCardNumber - 1])
  }


  useEffect(() => {
    // initalize card to 1 Apple
    updateRemix(1)
  }, []);

  return (
    <div className="gallery-app">
      <article className="gallery-article">
        {/* How to display title in button if data in JSON is fixed */}
        {/* <h2 className="card-title">Card {currentCard.card === 31 ? "" : currentCard.card}: {currentCard.name}</h2> */}
        <h3 key={Math.random()} className="gallery-card-title animate__animated animate__flipInX">{`Card:` + currentCard.name}</h3>
      </article>

      <aside className="gallery-card-list">
        {original.map((card) => (
          // use the url to focus the correct card based on the number in the url
          <button className="gallery-card-list-button" key={card.hash} autoFocus={card.card === 1} onClick={() => updateRemix(card.card)}>
            {/* Card: {card.card === 31 ? "" : card.card} {card.name} */}
            Card: {card.name === "Christies POAP" ? "Remixes" : card.name}
          </button>
        ))}
      </aside>

      <main className="gallery-card-viewer">
        {/* Do not use the CardHolder component here as the animation is different to the remix cards   */}
        <div key={Math.random()} className="gallery-card-viewer-card-holder animate__animated animate__bounceIn">
          <img className="gallery-card-viewer-image" src={`./images/original/poster_${currentCard.hash}${currentCard.fileType}`} loading="lazy" alt={`${currentCard.name}`} />
          <Details card={currentCard} />
        </div>

        {displayedRemixes.map((card) => {
          switch (card.fileType) {
            case '.png':
              return (
                <CardHolder>
                  <img className="gallery-card-viewer-image" src={`./images/remix/poster_${card.hash}${card.fileType}`} loading="lazy" alt={`${card.name}`} />
                  <Details card={card} />
                </CardHolder>)
            case '.jpeg':
              return (
                <CardHolder>
                  <img className="gallery-card-viewer-image" src={`./images/remix/poster_${card.hash}${card.fileType}`} loading="lazy" alt={`${card.name}`} />
                  <Details card={card} />
                </CardHolder>)
            case '.jpg':
              return (
                <CardHolder>
                  <img className="gallery-card-viewer-image" src={`./images/remix/poster_${card.hash}${card.fileType}`} loading="lazy" alt={`${card.name}`} />
                  <Details card={card} />
                </CardHolder>)
            case '.gif':
              return (
                <CardHolder>
                {/* // gif uses the image rather than the poster since gif is a pain to compress */}
                  <img className="gallery-card-viewer-image" src={`./images/remix/${card.hash}${card.fileType}`} loading="lazy" alt={`${card.name}`} />
                  <Details card={card} />
                </CardHolder>)

            case '.mp4':
              return (
                <CardHolder>
                  <video className="gallery-card-viewer-video" controls playsInline loop preload="metadata" poster={`./images/remix/${card.poster}${card.posterFileType}`} alt={`${card.name}`} >
                    <source src={`https://curiocardsremixdirectory.s3.amazonaws.com/images/remix/${card.hash}${card.fileType}`} type="video/mp4" />
                  </video>
                  <Details card={card} />
                </CardHolder>)

            case '.glb':
              return (
                <CardHolder>
                  {/* // this wrapper div is used to fix a UI bug that prevents the width being contorlled for the model-viewer */}
                  <div className="gallery-model-view-css-fix">
                    <model-viewer
                      alt="3d model"
                      src={`https://curiocardsremixdirectory.s3.amazonaws.com/images/remix/${card.hash}${card.fileType}`}
                      poster={`./images/remix/${card.poster}${card.posterFileType}`}
                      shadow-intensity="1"
                      camera-controls touch-action="pan-y">
                    </model-viewer>
                  </div>
                  <Details card={card} />
                </CardHolder>)
            default:
              return null
          }
        })}
      </main>

    </div>
  );
}

export default Gallery;
