import { Link, useParams, useResolvedPath } from "react-router-dom";
import {TfiTwitterAlt, TfiInstagram, TfiEmail} from "react-icons/tfi" 
import '../css/Card.css';
import { SiOpensea } from "react-icons/si" 
//TfiTwitterAlt

const Card = (props) => {
    var {original, remix} = props
    original = original.data
    remix = remix.data

    // combine both orginal and remix card arrays
    var cards = [... original, ...remix]
    console.log(cards)
    // get url path to filter with
    const { pathname } = useResolvedPath();
    // console.log(pathname)
    // destructure using / to get value in between
    const [blank, type, term] = pathname.split('/')
    const standardizedTerm = unescape(term)
    console.log(type)
    console.log(term)
    // 
    // const { collection, artist, title, terms } = useParams()
    // console.log(terms, collection)

    let getMedia = (card) => {
        //switch on card number
        // fix to swith on origin, original || remix
        let directory
        if(card.collection_listing !== "https://opensea.io/collection/curiocardswrapper"){
            directory = 'remix'
        }else{
            directory = 'original'
        }
        switch (card.fileType) {
          case '.png':
            return <img className="card-viewer-image" src={`../images/${directory}/poster_${card.hash}${card.fileType}`} loading="lazy" alt={`${card.name}`} key={card.hash} />
          case '.jpeg':
            return <img className="card-viewer-image" src={`../images/${directory}/poster_${card.hash}${card.fileType}`} loading="lazy" alt={`${card.name}`} key={card.hash} />
          case '.jpg':
            return <img className="card-viewer-image" src={`../images/${directory}/poster_${card.hash}${card.fileType}`} loading="lazy" alt={`${card.name}`} key={card.hash} />
            case '.gif':
              return <img className="card-viewer-image" src={`../images/${directory}/${card.hash}${card.fileType}`} loading="lazy" alt={`${card.name}`} key={card.hash} />                
          case '.mp4':
            // in production set to preload to metadata and have poster files 
            return (<video className="card-viewer-video" controls playsInline loop preload="metadata" poster={`../images/${directory}/${card.poster}${card.posterFileType}`} alt={`${card.name}`} key={card.hash}>
              <source src={`https://curiocardsremixdirectory.s3.amazonaws.com/images/remix/${card.hash}${card.fileType}`} type="video/mp4" />
            </video>)
          case '.glb':
            return (
            <div className="model-view-css-fix" key={card.hash}>
              <model-viewer
                alt="3d model"
                src={`https://curiocardsremixdirectory.s3.amazonaws.com/images/remix/${card.hash}${card.fileType}`}
                poster={`../images/${directory}/${card.poster}${card.posterFileType}`}
                shadow-intensity="1"
                camera-controls touch-action="pan-y">
              </model-viewer>
            </div>)
          default:
            return null
        }
      }


    let card = []
    switch (type) {
        case 'artist':
            card = [...cards.filter(obj => obj.artist === standardizedTerm)]
            break;
        case 'collection':
            // code block
            card = [...cards.filter(obj => obj.collection === standardizedTerm)]
            break;
        case 'card':
          // code block
          card = [...cards.filter(obj => obj.hash === standardizedTerm)]
          break;            
        default:
        // code block
    }
    // add media links for artists and not the collection
    // may change in the future
    let mediaLinks = 
      type === 'artist' || type === 'card' ?
        <span className="description-links">
                  {/* {card[0].email.length > 0 ?                         
                  <a href={`${card.email}`} target="_blank" rel="noreferrer">
                      <TfiEmail />
                  </a> : ""}                            */}
                  {card[0].twitter.length > 0 ?                         
                  <a href={`${card.twitter}`} target="_blank" rel="noreferrer">
                      <TfiTwitterAlt />
                  </a> : ""}                        
                  {/* {card[0].instagram.length > 0 ?                         
                  <a href={`${card.instagram}`} target="_blank" rel="noreferrer">
                      <TfiInstagram />
                  </a> : ""}                       */}
        </span> : ""

    console.log(card)
    let [thisCard] = card
    return (
        
        <div className="card-app">
            <main className="description-bar">

            {/* <h1 className="description-type">{type}</h1>
                  <h1 className="description-term">{type === 'card' ? card[0].name : unescape(term)}</h1> */}
                <h2>{thisCard.name}</h2>
                <p>{thisCard.description}</p>
                <p>Artist: {thisCard.artist}</p>
                {/* This data is very tedious to get so we need to automate it some how  */}
                {/* <p>Supply: 1000</p>
                <p>Issuance: November 2018</p> */}
                {/* <p>Price: 0.2 ETH</p> use Reservoir API to get later perhaps */}
                {/* <a className="opensea-link"><TfiHome /></a> */}
                <a className="card-listing opensea-link" href={thisCard.marketplace}  target="_blank" rel="noreferrer">
                  <SiOpensea />
                  <span>    </span>
                  {thisCard.collection}
                </a>   
                {/* <p>{thisCard.title}</p> */}
                
                {/* {mediaLinks} */}
            </main>
            <article className="card-results" >
            
            {card.map((card, index) => {
                // console.log(card)
                let cardMedia = getMedia(card)  
                console.log(cardMedia)  
                return (

                    <section className="card-card" key={index}>
                    <h2>{card.type}</h2>    

                        {cardMedia}

                    </section>
                )
            })}
            </article>
        </div>
    )
}
export default Card